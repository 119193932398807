@import './variables';

.loading-container{
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    p{
        font-weight: bold;
        text-transform: uppercase;
    }
}

.lot-number-search-container{
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 20px auto;
    transition: 0.1s border;
    box-shadow: 0 3px 5px -1px #a5a5a5;
    border-radius: 5px;
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    select{
        border: none;
        border-radius: 5px 0 0 5px;
        min-width: 75px;
        flex-grow: 1;
        flex-basis: 0;
        width: 100%;
        max-width: 350px;
        height: 50px;
        padding: 0 7px;
        background-color: #e6e6e6;
    }
    select:focus-visible{
        outline: none;
    }
    input{
        border: none;
        padding: 0 7px;
        min-width: 175px;
        width: 100%;
        max-width: 250px;
    }
    input:focus{
        outline: none;
        // border-top: 2px solid $secondary-color-tint;
        // border-bottom: 2px solid $secondary-color-tint;
    }
    button{
        border: none;
        color: white;
        background-color: $secondary-color;
        border-radius: 0 5px 5px 0;
        padding: 0 10px;
        cursor: pointer;
        transition: 0.5s color, 0.5s background-color;
        min-width: 50px;
        width: 100%;
        max-width: 100px;
    }

    button:hover{
        color: whitesmoke;
        background-color: $secondary-color-tint;
        transition: 0.5s color, 0.5s background-color;
    }
}

.lot-number-search-container:focus-within{
    border-bottom: 2px solid $secondary-color-tint;
    border-radius: 8px;
    transition: 0.1s border;
}
