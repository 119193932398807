@import './variables';

.navbar{
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: height 1s, background-color 1s;
    border-radius: 0 0 20px;
    height: 0px;
    .navbar-button{
        color: $primary-color;
        position: absolute;
        top: 10px;
        left: 10px;
        transform: rotate(0deg);
        transition: transform 1s, color 1s;
        cursor: pointer;
    }
    .navbar-button-open{
        color: whitesmoke;
        position: absolute;
        top: 10px;
        left: 10px;
        transform: rotate(450deg);
        transition: transform 1s, color 1s;
        cursor: pointer;

    }
    ul{
        margin: 0;
        padding: 0;
        font-weight: bold;
        li{
            font-size: 18px;
            margin: 10px;
            a{
                color: whitesmoke;
                text-decoration: none;
            }
        }
        li:last-child{
            padding-bottom: 20px;
        }
    }
}

.navbar-open{
    background-color: $primary-color;
    transition: height 1s, background-color 1s;
    border-radius: 0 0 20px;
    height: 200px;
}