@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Urbanist:wght@100;400;700&display=swap');

$primary-color: #903393;
$primary-color-tint: #732976;
$primary-color-shade: #a65ca9;

$secondary-color: #6ac49e;
$secondary-color-tint: #559d7e;
$secondary-color-shade: #88d0b1;

$title-color: #903393;

$heading-font:'Fjalla One', sans-serif;
$paragraph-font: 'Urbanist', sans-serif;