.error-container{
    position: relative;
    border: 3px solid #D2370F;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    margin: 0 auto 10px;
    min-width: 250px;
    text-align: center;
    background-color: #F37353;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    max-width: 450px;
    .error-container-close{
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
}