@import './variables';

.document-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .folder-icon{
        cursor: pointer;
    }
    .links-container{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        a{
            margin: 4px 0;
            padding: 1px 0;
            color: $secondary-color;
            text-decoration: none;
            span{
                color: #494949;
                font-weight: bold;
            };
        }
    }
}