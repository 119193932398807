@import './variables';
@import './DocumentsList';
@import './Error';
@import './LotNumberSearch';
@import './Navbar';
@import './variables';

*{
    margin: 0;
    box-sizing: border-box;
}

.App{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    box-sizing: content-box;
    .content-container{
        width: 80%;
        max-width: 320px;
        margin: 0 auto;
        box-sizing: content-box;
        min-height: 400px;
        .title{
            text-align: center;
        }
        .subtitle{
            text-align: center;
            max-width: 550px;
            font-size: 1.15em;
            margin: 0 auto;
        }
    }
}

.title{
    color: $title-color;
    margin: 10px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font;
    letter-spacing: 1.25px;
}

p, a, ul, li {
    font-family: $paragraph-font;
}

@media screen and (min-width: 640px) {
    .App{
        .content-container{
            max-width: 1440px !important;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}